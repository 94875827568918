@import './theme';

*,
*::before,
*::after {
    box-sizing: border-box;
}

#root {
    display: flex;
    min-height: 100vh;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color-1;
    color: $color-2-2;
    font-weight: 400;
    overflow-y: scroll;
}

a {
    color: $color-2-2;
    text-decoration: none;
}

button {
    cursor: pointer;
}

textarea,
input:focus {
    outline-color: $color-5-2;
}

input[type="date"] {
    -webkit-appearance: none;
    color: $color-2-2;
}

textarea::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}