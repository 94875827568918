@import 'theme.scss';

.article {
    margin-left: 40px;

    @media (max-width: $mobileMax) {
        margin: 0;
    }

    .article__title {
        color: $color-1-5;
        margin: 20px 0 0;

        span{
            font-size: 16px;
            font-weight: 400;
            margin-left: 10px;
        }
    }

    .article__list {
        margin: 10px 0 0;

        li {
            margin-top: 10px;
            font-size: 18px;
            line-height: 1.2;
            color: $color-2-2;
        }
    }
}