@import "theme";

.HeaderWithTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid $color-5-1;
    background-image: url('./bg1.png');
    margin-bottom: 10px;
    min-height: 84px;
    flex-wrap: wrap;
    gap: 15px;

    @media (max-width:900px) {
        padding-left: 60px;
    }

    h1 {
        font-size: 20px;
        opacity: 80%;
        margin: 0;
        color: $color-2;

        @media (max-width: $mobileMax) {
            font-size: 18px;
        }
    }

    .button {
        margin-left: auto;
    }
}

.HeaderWithTitle--customer {
    @media (max-width:900px) {
        flex-direction: column;
        padding: 0 20px;
    }

    .HeaderWithTitle__return {
        display: flex;
        align-items: center;
        align-self: center;
        gap: 10px;
        background: none;
        border: none;
        font-size: 24px;
        font-weight: 500;
        color: $color-2-2;

        @media (max-width:900px) {
            padding: 20px 0;
            margin: 40px auto 0 0;
            font-size: 20px;

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    .HeaderWithTitle--customer__customer {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        margin: 0 auto 0 15px;

        @media (max-width:900px) {
            margin: 0 0 10px;
        }

        .customer__avatar {
            grid-row: span 2;
            width: 70px;
            height: 70px;
            margin-right: 10px;
        }

        .customer__name {
            margin: 0;

            @media (max-width:900px) {
                font-size: 18px;
                margin: 0;
            }
        }

        .customer__id {
            grid-column: 2;
            margin: -10px 0 0;

            @media (max-width:900px) {
                font-size: 14px;
                margin: -20px 0 0;
            }
        }
    }

}