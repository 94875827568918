@import 'theme';

.removeButton {
    background-color: $color-4-1;
}

.removeButton:hover {
    background-color: $color-4-2
}

.removeButton:active {
    background-color: $color-4-3
}

.blur {
    filter: blur(2px);
}

.modalMenu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: start;
    text-align: center;
    position: fixed;
    background-color: $color-1;
    padding: 20px 30px;
    inset: 0;
    margin: auto;
    max-width: 310px;
    max-height: max-content;
    box-shadow: $shadow-1;
    border-radius: 12px;
    z-index: 5;

    p {
        grid-column: span 2;
    }
}

.test {
    display: none;
}

.editor {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    gap: 5px 30px;
    margin: 0 0 40px;

    @media (max-width: $mobileMax) {
        grid-template-columns: 1fr;
    }

    .editor__block {
        display: flex;
        flex-direction: column;

        h2 {
            margin: 0 0 10px;
        }
    }

    .editor__block:nth-child(1) {
        grid-column: 1;
        grid-row: span 3;

        @media (max-width: $mobileMax) {
            grid-row: 1;
        }
    }

    .inputLabel {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        color: $color-1-4;

        @mixin inputStyle {
            border: 1px solid $color-5-1;
            padding: 10px 20px;
            border-radius: 7px;
        }

        input {
            @include inputStyle()
        }

        textarea {
            resize: none;
            min-height: 100px;
            width: 100%;

            @include inputStyle()
        }
    }

    .inputLabel--special {
        margin-top: 20px;
    }

    .checkboxLabel {
        display: flex;
        gap: 5px;
        margin-bottom: 5px;

        input {
            flex-shrink: 0;
            width: 15px;
            height: 15px;
        }
    }

    .editor__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        grid-column: 2;
        justify-self: end;
        gap: 10px;

        @media (max-width: $mobileMax) {
            grid-column: 1;
            margin-top: 10px;
        }
    }
}