@import "theme.scss";

.settingsBlock {
    .settingsBlock__logs {
        display: flex;
        flex-direction: column;
        border: 1px solid $color-5-1;
        padding: 0 20px 30px;
        border-radius: 10px;
        margin-top: 20px;

        @media (max-width: $mobileMax) {
            padding: 0 5px 10px;
            border-radius: 5px;
        }

        h2 {
            color: $color-2-4;
            margin: 15px 0 15px;

            @media (max-width: $mobileMax) {
                margin: 15px 10px 0px;
            }
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                margin: 0;
                word-wrap: break-word;

                @media (max-width: $mobileMax) {
                    font-size: 12px;
                }
            }

            li:nth-child(2n) {
                background-color: $color-1-1;
                filter: brightness(95%);
            }
        }

        .warn.warn {
            background-color: $color-6-1;
        }

        .error.error {
            background-color: $color-4-0;
        }

        .attention.attention {
            background-color: $color-5-2;
        }

        .success.success {
            background-color: $color-7-1;
        }
    }
}