@import '../../theme.scss';

.navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 35px;
    background-color: $mainColor;
    min-width: 250px;
    padding: 20px;

    @media (max-width:900px) {
        position: fixed;
        height: 100vh;
        z-index: 4;
        transform: translateX(-260px);
        transition: 200ms;
    }

    .header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;

        p {
            color: $color-1;
            font-size: 16px;
        }
    }

    a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $color-1;
        font-size: 16px;
        transition: 100ms;

        svg {
            width: 26px;
            height: 26px;
            padding: 0;
            margin: 0;
        }
    }

    a:hover {
        text-decoration: underline;
    }

    a.active {
        font-weight: 700;
        transform: scale(1.05);
        text-decoration: none;
    }

    .copyright {
        color: $color-1;
        margin-top: auto;
        opacity: 50%;
    }
}

.navigation--show {
    transform: translateX(0);
}

.mobileButton {
    display: none;
    position: absolute;
    margin: 15px 10px;
    padding: 10px;
    background: none;
    border: none;

    @media (max-width:900px) {
        display: flex;
    }
}

.mobileBackground {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;
}

.mobileBackground--show {
    display: block;
}