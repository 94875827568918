@import '../../theme';

.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20vh;
    width: 100%;

    .error {
        color: $color-4-4;
    }

    .auth__form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
        min-width: 270px;

        .button {
            background-color: $color-3;
            color: $color-1;
            border: none;
            border-radius: 6px;
            padding: 12px 20px;
            font-weight: 700;
            font-size: 15px;

            &:hover {
                filter: brightness(110%);
            }
        }

        .input {
            border-radius: 6px;
            padding: 12px 20px;
            border: none;
            box-shadow: $shadow-2;
        }
    }

    .auth__informText {
        font-size: 14px;
        text-align: center;
        max-width: 800px;
        margin: 0;
    }
}