@import "theme.scss";

.search {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 350px;
    width: 100%;

    @media (max-width: $mobileMax) {
        margin: 0 auto;
    }

    .ico {
        position: absolute;
        margin-left: 10px;
        pointer-events: none;
    }
}