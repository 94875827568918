@import 'theme';

.information {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    margin-top: 20px;

    .card {
        border: 1px solid $color-5-1;
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        max-width: 442px;
        position: relative;

        .card__avatar {
            position: absolute;
            right: 15px;
            top: 15px
        }

        .card__title {
            margin: 0 0 10px;
            font-size: 20px;
        }

        p {
            margin: 0;
            color: $color-2;
            font-size: 16px;

            span {
                color: $color-2-5;
            }
        }

        .marginTop {
            margin-top: 10px;
        }

        .card__lastText {
            margin-right: 40px;
        }

        .socialButton {
            display: flex;
            position: absolute;
            right: 10px;
            bottom: 10px;
            text-decoration: none;
        }
    }
}