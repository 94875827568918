@import "theme.scss";

.alertsContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    align-items: flex-end;
    gap: 10px;
    right: 25px;
    top: 50px;
    z-index: 6;

    @media (max-width: $mobileMax) {
        top: 25px;
    }

    .alert {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px 40px 15px 20px;
        background-color: rgb(134, 126, 8);
        border-radius: 7px;
        box-shadow: 2px 2px 5px #00000044;
        max-width: 320px;
        width: 100%;
        color: $color-1;
        font-size: 14px;
        font-weight: 500;

        @media (max-width: $mobileMax) {
            max-width: 280px;
            font-size: 14px;
        }

        svg {
            flex-shrink: 0;
            width: 25px;
            height: 25px;
            color: $color-1;
        }
    }

    .success {
        background-color: rgb(32, 192, 10);
    }

    .warn {
        background-color: rgb(9, 169, 233);
    }

    .error {
        background-color: rgb(238, 66, 66);
    }
}