@import 'theme';

.dateSelector {
    border: 1px solid $color-5-1;
    padding: 10px 20px;
    color: $color-2-2;
    background-color: $color-1;
    font-weight: 500;
    border-radius: 8px;
    height: 42px;
}

.finance {
    padding-bottom: 40px;

    .finance__dateViewChanger {
        display: flex;
        gap: 10px;

        button {
            border: none;
            padding: 5px 10px;
            border-radius: 4px;
            opacity: 30%;
        }

        .active {
            opacity: 100%;
        }
    }

    .finance__navigation {
        display: flex;
        gap: 10px;
        display: none;
        margin: 20px 0;

        @media (max-width:1450px) {
            display: flex;
        }

        button {
            padding: 10px 20px;
            opacity: 30%;
            border: none;
            border-radius: 7px;
        }

        .active {
            opacity: 100%;
        }
    }

    input {
        min-width: 200px;
    }

    .generalPrice {
        display: flex;
        gap: 10px;
        font-weight: 500;

        b {
            font-weight: 900;
        }

        .green {
            color: rgb(0, 184, 0);
        }

        .red {
            color: rgb(161, 0, 0);
        }
    }

    .generalPrice--special {
        justify-content: space-between;
    }

    .finance__section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;

        @media (max-width: 1450px) {
            flex-direction: column;
            align-items: center;
        }

        .section__block {
            width: 100%;

            @media (max-width: 1450px) {
                display: none;
            }

            form {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                width: 100%;
                gap: 5px;
                margin-bottom: 5px;

                @media (max-width: $mobileMax) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: 2;
                }

                input {
                    min-width: 60px;
                    padding: 10px 5px 10px 10px;
                    grid-template-rows: 2;

                    @media (max-width: $mobileMax) {
                        grid-row: 2;
                    }
                }

                .name {
                    grid-row: 1;
                    grid-column: span 3;

                    @media (max-width: $mobileMax) {
                        grid-row: 1;
                        grid-column: span 3;
                    }
                }
            }

            table {
                display: flex;
                flex-direction: column;
                border: 1px solid $color-5-1;
                width: 100%;
                border-radius: 10px;

                @media (max-width: $mobileMax) {
                    font-size: 12px;
                }

                tbody {
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                    width: 100%;

                    th {
                        padding-bottom: 10px;

                        @media (max-width: $mobileMax) {
                            font-size: 11px;
                        }
                    }

                    td {
                        padding: 7px 0;
                        width: 100%;
                    }
                }
            }

            .table1 {
                caption {
                    display: flex;
                    text-align: start;
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $color-5-1;
                    padding: 10px;
                }

                thead {
                    tr {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 130px;

                        @media (max-width: $mobileMax) {
                            grid-template-columns: 1.3fr 0.9fr 0.9fr auto;
                        }
                    }

                    .price {
                        text-align: start;

                        @media (max-width: $mobileMax) {
                            text-align: center;
                        }
                    }
                }

                tbody {
                    tr {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 130px;
                        width: 100%;
                        text-align: center;
                        border-bottom: 1px solid $color-5-1;

                        @media (max-width: $mobileMax) {
                            grid-template-columns: 1fr 1fr 1fr 50px;
                            gap: 0 5px;
                        }

                        &:nth-last-child(1) {
                            border-bottom: none;
                        }
                    }

                    .name {
                        color: $color-5-8;
                        cursor: pointer;

                        @media (max-width: $mobileMax) {
                            text-align: start;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .date {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .price {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                    }

                    .extraPays {
                        display: grid;
                        grid-template-columns: 1fr 130px;
                        grid-column-start: 3;
                        grid-column-end: 5;


                        @media (max-width: $mobileMax) {
                            grid-template-columns: 1fr 50px;
                            padding: 3px 0;
                        }

                        .extraPays__first {
                            display: flex;
                            align-items: center;
                            margin-right: auto;
                            gap: 5px;
                        }
                    }
                }
            }

            .table2 {
                tbody {
                    tr {
                        display: grid;
                        grid-template-columns: 1fr 100px 80px 20px;
                        width: 100%;
                        text-align: center;
                        border-bottom: 1px solid $color-5-1;

                        @media (max-width: $mobileMax) {
                            grid-template-columns: 1fr 80px 80px 20px;
                        }

                        &:nth-last-child(1) {
                            border-bottom: none;
                        }

                        .name {
                            text-align: start;
                        }

                        .remove {
                            padding: 0;
                            border: none;
                            background: none;
                            color: $color-2-2;
                            margin-left: auto;
                        }
                    }
                }
            }
        }

        .showSectionBlock {
            display: table;
        }

        .section__block--first {
            max-width: 640px;

            @media (max-width: 1450px) {
                max-width: 100%;
            }
        }

        .section__block--second {
            max-width: 510px;

            @media (max-width: 1450px) {
                max-width: 100%;
            }
        }
    }
}