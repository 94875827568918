$mainColor: #183348;

$color-1: #ffffff;
$color-1-1: #f8f8f8;
$color-1-1-1: #eeeeee;
$color-1-2: #dddddd;
$color-1-3: #c7c7c7;
$color-1-4: #aaaaaa;
$color-1-5: #949494;

$color-2: #000;
$color-2-2: #2c2c2c;
$color-2-4: #555;
$color-2-5: #6e6e6e;

$color-3: #7db4b4;

$color-4-0: #ffa7a7;
$color-4-1: #ff5b5b;
$color-4-2: #ff3131;
$color-4-3: #eb1414;
$color-4-4: #d91212;

$color-5: #ebeeff;
$color-5-1: #D9E3F4;
$color-5-2: #b8c3ff;
$color-5-3: #8496ff;
$color-5-4: #5783FF;
$color-5-5: rgba(87, 131, 255, 0.933);
$color-5-6: rgba(87, 131, 255, 0.8);
$color-5-7: #536DFE;
$color-5-8: #36709c;

$color-6: #fff9c3;
$color-6-1: #ffff73;

$color-7: #35CD00;
$color-7-1: #beffae;

$shadow-1: 1px 3px 10px rgba(0, 0, 0, 0.188);
$shadow-2: 1px 2px 4px rgba(0, 0, 0, 0.188);

$mobileMax: 767px;
$tabletMax: 1200px;