@import 'theme';

.editButton {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 20px;
    background: none;
    color: $color-5-7;
    border: 1px solid $color-5-1;
    background-color: $color-1;
    border-radius: 8px;
    font-weight: 500;

    @media (max-width:900px) {
        margin-bottom: 10px;
    }
}

.editButton:hover {
    background-color: $color-5-7;
    color: $color-1;
}

.adminNote {
    background-color: $color-6;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 10px;
    max-width: 500px;
}

.editableForm {

    .form__select,
    input {
        background-color: rgb(172, 255, 172);
    }
}

.visits {
    border: 1px solid $color-5-1;
    border-radius: 10px;
    margin: 40px 0 60px;

    @media (max-width: $mobileMax) {
        border: none;
        padding: 0;
    }

    .visits__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid $color-5-1;
        margin-bottom: 40px;

        @media (max-width: $mobileMax) {
            flex-direction: column;
            border: none;
            padding: 0;
        }

        .header__title {
            margin-right: auto;
            font-size: 26px;
        }

        form {
            display: grid;
            grid-template-columns: 1fr repeat(4, auto) 150px;
            gap: 10px;
            width: 100%;

            @media (max-width: $tabletMax) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;
            }

            .form__input {
                padding: 15px;
                width: 100%;
                max-width: 100%;
            }

            .form__input--long {
                flex-basis: 100%;
                grid-column: span 2;
            }

            .form__input--short {
                flex-basis: 25%;
                text-align: center;

                @media (max-width: $mobileMax) {
                    flex-basis: 48%;
                }
            }

            .form__secondaryBtn {
                color: $color-5-7;
                background-color: $color-1;
                border: 1px solid #536DFE;
                transition: 100ms;
                grid-column: span 3;
                margin-right: auto;

                @media (max-width: $tabletMax) {
                    grid-column: span 2;
                    margin: 0;
                }

                &:hover {
                    color: $color-1;
                    background-color: $color-5-7;
                    border-color: $color-1;
                }
            }

            .form__submitBtn {
                min-height: 47px;
                grid-column-start: 5;
                grid-column-end: 7;

                @media (max-width: $tabletMax) {
                    grid-column: 2;
                    margin-left: auto;
                    margin-top: 15px;
                }
            }

            .form__input--checkbox {
                margin: auto;
            }
        }
    }

    .form__paymentsIco {
        margin-left: 5px;
    }

    .visits__table {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        padding: 0 10px 20px;

        thead {
            display: flex;
            width: 100%;

            tr {
                display: grid;
                grid-template-columns: 1.3fr 0.9fr 0.9fr 0.9fr auto;
                gap: 10px;
                width: 100%;

                @media (max-width: $mobileMax) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr auto;
                    font-size: 12px;
                }
            }

            .hideMobile {
                @media (max-width: $mobileMax) {
                    display: none;
                }
            }
        }

        tbody {
            width: 100%;

            tr {
                display: grid;
                grid-template-columns: 1.3fr 0.9fr 0.9fr 0.9fr auto;
                gap: 0 5px;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid $color-5-1;

                @media (max-width: $mobileMax) {
                    display: grid;
                    grid-template-columns: 1fr 1.2fr 0.8fr auto;
                }

                &:nth-last-child(1) {
                    border: none;
                }

                td {
                    margin: auto;
                    padding-top: 10px;
                    grid-row: span 2;
                    align-self: center;
                    width: 100%;

                    @media (max-width: $mobileMax) {
                        padding-top: 0;
                        margin-top: 5px;
                        font-size: 14px;
                    }
                }

                .name {
                    grid-column: 1;
                    grid-row: 1;
                    margin-right: auto;

                    @media (max-width: $mobileMax) {
                        text-align: start;
                    }
                }

                .date {
                    @media (max-width: $mobileMax) {
                        grid-column: 1;
                        grid-row: 2;
                        font-size: 12px;
                        color: $color-1-4;
                        text-align: start;
                        grid-row: 2;
                        line-height: 1;
                    }
                }

                .price {
                    display: flex;
                    align-items: center;
                    margin-right: auto;
                    text-align: start;
                }

                .extraPay {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 1fr 1fr;
                    grid-column-start: 3;
                    grid-column-end: 5;
                    width: 100%;
                    text-align: start;

                    @media (max-width: 768px) {
                        grid-template-columns: 1.3fr 0.8fr;
                        grid-column-start: 2;
                        grid-column-end: 4;
                    }

                    .extraPay__last {
                        text-align: center;
                    }
                }
            }

            .editableButton {
                border: none;
                background: none;
                color: $color-2-2;
            }

            .editableButton--remover {
                color: $color-4-4;
                position: absolute;
                margin-left: -30px;
            }

            .green {
                color: rgb(34, 202, 34)
            }
        }
    }
}