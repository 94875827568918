@import "theme.scss";

.button {
    background-color: $color-5-6;
    padding: 10px 20px;
    border-radius: 10px;
    color: $color-1;
    border: none;
    cursor: pointer;

    @media (max-width: $mobileMax) {
        font-size: 14px;
    }
}

.button:hover {
    background-color: $color-5-5;
}

.button:active {
    background-color: $color-5-4;
}