@import "theme.scss";

.loading {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;

    .loading__ico {
        margin: 0 auto;
        color: $color-5-6
    }
}