@import './theme';

.filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 10px 0;
    border: 1px solid $color-5;
    padding: 20px 10px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    margin-left: auto;

    label {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        max-width: 400px;
        width: 100%;

        input {
            padding: 7px 10px;
            border: 1px solid $color-5;
            border-radius: 5px;
            margin-top: 5px;
            width: 100%;
            background: none;
            text-align: left;
            min-height: 32px;
        }
    }

    .clearButton {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-5;
        padding: 5px 15px;
        margin-left: auto;
        background: none;
        border-radius: 7px;
        font-size: 14px;

        svg {
            width: 15px;
            height: 15px;
            opacity: 80%;
            color: $color-2-2;
        }
    }
}

.customersHeader {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 15px;
    margin-bottom: 20px;

    @media (max-width: $mobileMax) {
        margin-bottom: 5px;
    }

    .filterButton {
        margin-left: auto;
        padding: 0;
        border: none;
        background: none;
        opacity: 40%;
        margin-top: 10px;

        svg {
            @media (max-width: $mobileMax) {
                width: 30px;
                height: 30px;
            }
        }
    }

    .filterButton--active {
        opacity: 100%;
    }
}

.customers {
    border: 1px solid $color-5;
    width: 100%;
    border-radius: 10px;
    padding: 20px 0 0;
    margin: 15px 0 40px;
    border-bottom: none;

    @media (max-width: $mobileMax) {
        border: none;
        padding: 20px 0;
        margin-top: 0;
        padding: 0;
    }

    thead {
        tr {
            @media (max-width: $mobileMax) {
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                font-size: 14px;
                margin-bottom: 20px;

                .hideMobile {
                    display: none;
                }
            }
        }
    }

    tbody {
        text-align: center;

        tr {

            td {
                border-bottom: 1px solid $color-5;
                padding-top: 3px;

                @media (max-width: $mobileMax) {
                    border: none;
                    padding: 0;
                }
            }

            @media (max-width: $mobileMax) {
                display: grid;
                grid-template-columns: auto 1fr auto auto;
                grid-template-rows: auto auto;
                text-align: start;
                width: 100%;
                margin-bottom: 5px;
                padding-bottom: 5px;
                border-bottom: 1px solid $color-5;
                cursor: pointer;

                .avatar {
                    display: flex;
                    width: 39px;
                    height: 39px;
                    padding: 0;
                    grid-column: 1;
                    grid-row: span 2;
                    margin-right: 10px;

                    button {
                        display: flex;

                        svg {
                            width: 39px;
                            height: 39px;
                        }
                    }
                }

                .name {
                    grid-column: 2;
                    font-size: 16px;
                    padding: 0;
                }

                .new {
                    text-align: start;
                }

                .phone {
                    grid-row: 2;
                    grid-column: 2;
                    color: $color-1-3;
                    font-size: 14px;
                    padding: 0;
                    margin-top: 0px;
                }

                .date {
                    font-size: 12px;
                    align-self: center;
                    margin-right: 10px;
                }

                .edit {
                    align-self: center;

                    @media (max-width: $mobileMax) {
                        display: none;
                    }

                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            button {
                padding: 0;
                background: none;
                border: none;
            }

            .new {
                color: $color-7;
                font-weight: 700;
                margin-left: 10px;
            }
        }
    }
}