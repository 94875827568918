@import 'theme';

.customSelector {
    display: flex;
    padding: 0;
    position: relative;
    width: 100%;
    margin: 0;

    .customSelector__visible--open {
        .customSelector__arrow {
            transform: rotate(180deg);
        }
    }

    .customSelector__unvisible {
        display: flex;
        flex-direction: column;
        background-color: $color-1;
        border: 1px solid $color-1-3;
        border-radius: 14px;
        padding: 10px 0;
        position: absolute;
        top: calc(100% + 5px);
        margin: 0;
        min-width: 300px;
        z-index: 2;

        li {
            display: grid;
            grid-template-columns: 45px 1fr;
            gap: 10px;
            align-items: center;
            padding: 7px 10px;
            border-top: 1px solid $color-1-2;
            cursor: pointer;
            font-size: 16px;
            min-height: 40px;

            &:hover {
                background-color: $color-1-1-1;
            }

            &:nth-child(1) {
                border: none;
            }

            svg {
                margin: auto;
                width: 42px;
                height: 42px;
                object-fit: cover;
            }
        }
    }
}

.customSelector__visible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: $color-5;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 8px;
    min-height: 47px;
    cursor: pointer;
    width: 100%;

    div {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 13px;
    }

    .customSelector__arrow {
        content: ">";
        margin: 0;
        font-size: 12px;
        color: $color-2-4;
        line-height: 1;
    }
}