@import 'theme';

.form__extraPay {
    grid-column-start: 1;
    grid-column-end: 7;

    display: grid;
    grid-template-columns: repeat(4, auto);
    border-top: 1px solid #eee;
    padding-top: 15px;
    gap: 10px;
    min-height: 47px;
    margin-top: 5px;

    @media (max-width: $tabletMax) {
        grid-column: span 2;
        grid-template-columns: 1fr 1fr;
    }

    .extraPay__removeBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        margin-left: auto;
        align-self: center;
        border: none;
        background: none;
    }
}