@import "theme.scss";

.createCustomerForm {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;
    margin-top: 10vh;
    width: 100%;
    padding: 25px;
    box-shadow: 2px 2px 10px #47474740;
    border-radius: 10px;

    @media (max-width: $mobileMax) {
       box-shadow: none;
       margin-top: 10px;
    }

    .createCustomerForm__header {
        display: grid;
        align-items: center;
        gap: 8px;
        grid-template-columns: auto 1fr 1fr 0.5fr;
        margin-bottom: 20px;

        @media (max-width: $mobileMax) {
            grid-template-columns: auto 1fr;

            .header__avatar {
                grid-column: 1;
                grid-row: span 3;
            }
        }

        input {
            padding: 20px;
        }
    }

    .createCustomerForm__body {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        gap: 8px;

        svg {
            flex-shrink: 0;
        }
    }

    textarea {
        min-height: 125px;
        padding: 20px;
    }

    button {
        margin-left: auto;
    }
}